import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import hash from 'object-hash';
import JoditEditor from 'jodit-react';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  message,
  Modal,
  Select,
  Tabs,
  Tag,
  Typography,
  Upload,
} from 'antd';
import {
  AppstoreAddOutlined,
  CloseCircleOutlined,
  DeleteFilled,
  DeleteOutlined,
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileImageOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import apiServices from 'src/services/apiServices';
import Title from 'antd/es/typography/Title';
import SimBody from 'src/components/simPreview/SimBody';
import ExhibitsTab from 'src/components/simEditor/tabs/exhibits';

export default function EssayTypeEditor() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCellModelOpen, setIsCellModalOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [newCell, setNewCell] = useState({
    type: 'dynselopt_',
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const propsData = useLocation();
  const navigate = useNavigate();

  const [editableObject, setEditableObject] = useState({});
  const [editableObjectMeta, setEditableObjectMeta] = useState({});
  const [change, setChange] = useState();

  const [originalHash, setOriginalHash] = useState();
  const [currentHash, setCurrentHash] = useState();

  const [popupSelected, setPopupSelected] = useState([]);

  const [questionJson, setQuestionJson] = useState({
    allSectionNames: [],
    fieldIds: [],
    totalSelectFields: [],
    simType: 'taskBased',
    sections: [],
    formulas: [],
  });

  const [questionData, setQuestionData] = useState({
    htmlCodeExam: '',
    htmlCodeResult: '',
    explanation: '',
  });

  const [formData, setFormData] = useState({
    first_name: [],
    last_name: [],
    email: [],
    phone: [],
    message: [],
    career: [],
    qualification: [],
    experience: [],
    current_compay: [],
    address: [],
    state: [],
    city: [],
    pincode: [],
    attach_file: [],
  });

  const [exhibits, setExhibits] = useState();
  const [newExhibit, setNewExhibit] = useState({
    name: '',
    exhibits_file: '',
  });

  useEffect(() => {
    console.log(propsData);
    setExhibits(propsData.state.Form.exhibits);
    // {console.log(propsData.state.Form.exhibits)}
    setFormData(propsData.state.Form);
    setQuestionData(propsData.state.Question);
    setQuestionJson({
      allSectionNames: propsData.state.Form.question_json.allSectionNames,
      fieldIds: propsData.state.Form.question_json.fieldIds,
      totalSelectFields: propsData.state.Form.question_json.totalSelectFields,
      simType: 'taskBased',
      sections: propsData.state.Form.question_json.sections,
      formulas: propsData.state.Form.question_json.formulas,
    });
  }, []);

  useEffect(() => {
    setEditableObject({ ...questionJson });
    setEditableObjectMeta({
      course_id: formData?.course?.id,
      solution_description: formData?.solution_description,
      chapter_id: formData?.chapter?.id,
      course_id: formData?.course?.id,
      level: formData?.level,
      pass_percentage: formData?.pass_percentage,
      question_number: formData?.idnumber,
      simulation_category: formData?.sim_type?.id,
      simulation_id: formData?.id,
      subject_id: formData?.subject?.id,
      exhibits_file: formData?.exhibits,
      answer: formData?.sim_answers?.map((e) => e?.answer),
    });
    setOriginalHash(
      hash({
        ...questionJson,
        ...{
          course_id: formData?.course?.id,
          solution_description: formData?.solution_description,
          chapter_id: formData?.chapter?.id,
          course_id: formData?.course?.id,
          level: formData?.level,
          pass_percentage: formData?.pass_percentage,
          question_number: formData?.idnumber,
          simulation_category: formData?.sim_type?.id,
          simulation_id: formData?.id,
          subject_id: formData?.subject?.id,
          exhibits_file: formData?.exhibits,
          answer: formData?.sim_answers?.map((e) => e?.answer),
        },
      })
    );
  }, [questionJson]);

  useEffect(() => {
    console.log({ currentHash, originalHash });
    if (currentHash?.trim() === originalHash?.trim()) {
      setChange(false);
    } else {
      setChange(true);
    }
  }, [currentHash, originalHash]);

  useEffect(() => {
    setCurrentHash(hash({ ...editableObject, ...editableObjectMeta }));
    console.log({ editableObject, editableObjectMeta });
  }, [editableObject, editableObjectMeta]);

  useEffect(() => {
    editableObject;
  }, [editableObject]);

  const handleSave = () => {
    console.log(editableObjectMeta, editableObject);

    apiServices
      .post('assessment/update-simulation/', {
        question: { ...editableObject },
        ...editableObjectMeta,
      })
      .then((e) => {
        console.log(e);
        apiServices.get(`assessment/get-simulation-detail/${editableObjectMeta.simulation_id}`).then((e) => {
          console.log(e);
          setEditableObject({ ...e[0].question_json });
          setEditableObjectMeta({
            course_id: e[0]?.course?.id,
            solution_description: e[0]?.solution_description,
            chapter_id: e[0]?.chapter?.id,
            course_id: e[0]?.course?.id,
            level: e[0]?.level,
            pass_percentage: e[0]?.pass_percentage,
            question_number: e[0]?.idnumber,
            simulation_category: e[0]?.sim_type?.id,
            simulation_id: e[0]?.id,
            subject_id: e[0]?.subject?.id,
            exhibits_file: e[0]?.exhibits,
            answer: formData?.sim_answers?.map((e) => e?.answer),
          });
          setOriginalHash(
            hash({
              ...e[0]?.question_json,
              ...{
                course_id: e[0]?.course?.id,
                solution_description: e[0]?.solution_description,
                chapter_id: e[0]?.chapter?.id,
                course_id: e[0]?.course?.id,
                level: e[0]?.level,
                pass_percentage: e[0]?.pass_percentage,
                question_number: e[0]?.idnumber,
                simulation_category: e[0]?.sim_type?.id,
                simulation_id: e[0]?.id,
                subject_id: e[0]?.subject?.id,
                exhibits_file: e[0]?.exhibits,
                answer: formData?.sim_answers?.map((e) => e?.answer),
              },
            })
          );
        });
      });

    // setOriginalHash(hash({
    //     ...editableObject, ...editableObjectMeta
    // }))
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      console.log(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleParagraphChange = (e, sectionName) => {
    setEditableObject((prevObject) => {
      const sectionIndex = prevObject['sections'].findIndex((e) => Object.keys(e)[0] === sectionName);
      if (sectionIndex === -1) {
        return prevObject;
      } else {
        const newObject = { ...prevObject };
        newObject['sections'][sectionIndex][sectionName].value = e;
        return newObject;
      }
    });
  };

  const handleOptionChange = (e, optionIndex, cellIndex, rowIndex, sectionName, sectionIndex) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject['sections'][sectionIndex][sectionName].rows[rowIndex][cellIndex].options[optionIndex] = e.target.value;
      return newObject;
    });
    refreshAnswers();
  };

  const addOption = (e, cellIndex, rowIndex, sectionName, sectionIndex) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject['sections'][sectionIndex][sectionName].rows[rowIndex][cellIndex].options.push('');
      return newObject;
    });
  };

  const removeOption = (e, option, cellIndex, rowIndex, sectionName, sectionIndex) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject['sections'][sectionIndex][sectionName].rows[rowIndex][cellIndex].options = newObject['sections'][
        sectionIndex
      ][sectionName].rows[rowIndex][cellIndex].options.filter((e) => e !== option);
      return newObject;
    });
  };

  const changeRowExplanation = (e, rowIndex, sectionName, sectionIndex) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject['sections'][sectionIndex][sectionName].rowExplanations[rowIndex] = e;
      return newObject;
    });
  };

  const removeRow = (rowIndex, sectionName, sectionIndex) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject['sections'][sectionIndex][sectionName].rowExplanations = [
        ...newObject['sections'][sectionIndex][sectionName].rowExplanations.slice(0, rowIndex),
        ...newObject['sections'][sectionIndex][sectionName].rowExplanations.slice(rowIndex + 1),
      ];
      newObject['sections'][sectionIndex][sectionName].rows = [
        ...newObject['sections'][sectionIndex][sectionName].rows.filter((e, i) => i !== rowIndex),
      ];
      return newObject;
    });
  };

  const handleChangeAnswer = (e, cellIndex, rowIndex, sectionName, index) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject['sections'][index][sectionName].rows[rowIndex][cellIndex].value = e;
      return newObject;
    });
    refreshAnswers();
  };

  const updateFieldById = (id, value) =>
    new Promise((resolve, reject) => {
      try {
        console.log(`Updating Field ${id} by ${value}`);
        setEditableObject((prevObject) => {
          let newObject = { ...prevObject };
          for (let section of newObject.sections) {
            if (Object.values(section)[0].type === 'table') {
              for (let row of Object.values(section)[0]?.rows) {
                for (let cell of row) {
                  if (cell.type === 'input' && cell.id === id) {
                    cell.value = value;
                  }
                }
              }
            }
          }
          return newObject;
        });
        refreshAnswers();
      } catch (error) {
        reject(error);
      } finally {
        resolve();
      }
    });

  const getFieldValue = (id) => {
    for (let section of editableObject.sections) {
      if (Object.values(section)[0].type === 'table') {
        for (let row of Object.values(section)[0]?.rows) {
          for (let cell of row) {
            if (cell.type === 'input' && cell.id === id) {
              if (typeof cell.value === 'string') {
                return parseFloat(cell.value.replace(/,/g, ''));
              } else if (typeof cell.value === 'number') {
                return cell.value;
              }
            }
          }
        }
      }
    }
  };

  function calculateValue(ids, formula) {
    // Get the values of the input fields specified in the ids array
    const fieldValues = ids.map((id) => getFieldValue(id));
    console.log({ ids, formula });

    if (ids.length === 1 && ids[0].split('_')[1] === formula) {
      return fieldValues[0];
    }

    // Replace the field ids in the formula with their corresponding values
    let formulaWithValues = formula;
    ids.forEach((id, index) => {
      // console.log(id, fieldValues[index])
      formulaWithValues = formulaWithValues.replace(id.split('_')[1], fieldValues[index]);
    });

    console.log({ formulaWithValues });

    // Evaluate the formula
    const result = eval(formulaWithValues);
    console.log({ result });

    return result;
  }

  const isPresentInFormula = (id) => {
    return editableObject.formulas?.filter(({ ids, formula, resultField }) => ids.includes(id)).length > 0;
  };

  const updateRespectiveFields = async (id) => {
    console.log(id);
    let isPartOfFormula = editableObject.formulas?.filter((e) => e.ids.includes(id)) || [];
    for (let j of isPartOfFormula) {
      await updateFieldById(j.resultField, calculateValue(j.ids, j.formula));
      console.log('Depth found', isPresentInFormula(j.resultField), j.resultField);
      if (isPresentInFormula(j.resultField)) {
        updateRespectiveFields(j.resultField);
      }
    }
  };

  const handleInputChange = (e, cellIndex, rowIndex, sectionName, index) => {
    let inputValue = e.target.value;
    let isResultFieldInFormula = editableObject.formulas?.filter(
      (e) => e.resultField === editableObject.sections[index][sectionName].rows[rowIndex][cellIndex].id
    );
    if (isResultFieldInFormula?.length > 0) {
      message.info('This is field is a result field of Formula and is non-editable.');
    } else {
      setEditableObject((prevObject) => {
        const newObject = { ...prevObject };
        // // Update values where this cell value is a key,
        let ans = 0;
        if (inputValue === '' || inputValue === undefined || inputValue === null) {
          ans = 0;
        } else {
          ans = parseCurrencyString(inputValue);
          console.log({ ans });
        }

        newObject.sections[index][sectionName].rows[rowIndex][cellIndex].value = ans;
        console.log('Starting Value', newObject.sections[index][sectionName].rows[rowIndex][cellIndex]);
        updateRespectiveFields(newObject.sections[index][sectionName].rows[rowIndex][cellIndex].id);
        return newObject;
      });
    }

    refreshAnswers();
  };

  const handlePrefixChange = (e, cellIndex, rowIndex, sectionName, index) => {
    let inputValue = e.target.value;

    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      // // Update values where this cell value is a key,
      newObject.sections[index][sectionName].rows[rowIndex][cellIndex].prefix = inputValue;
      return newObject;
    });
  };

  const handleTextAreaChange = (e, cellIndex, rowIndex, sectionName, index) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject.sections[index][sectionName].rows[rowIndex][cellIndex].value = e.target.value;
      return newObject;
    });
  };

  const UpdateMeta = (e, type, isJodit) => {
    if (isJodit) {
      setEditableObjectMeta((prevEditableObjectMeta) => {
        const newObject = { ...prevEditableObjectMeta };
        newObject[type] = e;
        return newObject;
      });
      return;
    }
    console.log(editableObjectMeta[type]);
    setEditableObjectMeta((prevEditableObjectMeta) => {
      const newObject = { ...prevEditableObjectMeta };
      newObject[type] = e.target.value;
      return newObject;
    });
  };

  const isCircular = (inputFields) => {
    if (inputFields.length === 1) {
      let inputFieldHasFormulaAttached = editableObject.formulas?.filter(
        (f) => f.resultField === `dyninputfield_${inputFields[0]}`
      );
      if (inputFieldHasFormulaAttached.length > 0) {
        console.log(inputFieldHasFormulaAttached[0].ids[0], inputFields[0]);
        return inputFieldHasFormulaAttached[0].ids[0] === inputFields[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
    return false;
  };

  const handleFormulaUpdate = (e) => {
    console.log('handleFormulaUpdate', e);
    let inputVal = popupSelected.inputVal;
    if (popupSelected.inputVal === '') {
      message.error("Formula field can't be empty. If you want to remove formula, press 'Remove Formula' button.");
      return;
    }
    let inputFields = inputVal.match(/DIF\d*/g);
    let fieldsArr = editableObject.fieldIds.map((e) => e.split('_')[1]);
    let unknownIds = inputFields.filter((f) => !fieldsArr.includes(f));
    console.log(
      'Hey',
      inputFields[0],
      popupSelected,
      popupSelected?.id,
      popupSelected?.id?.split('_')[1],
      inputFields?.length
    );
    if (unknownIds.length > 0) {
      console.log({ unknownIds, inputFields, fieldsArr });
      message.error(`Unknown Ids : ${unknownIds.join(', ')}`);
      return;
    } else if (isCircular(inputFields)) {
      message.error('Circular Formula Found, Current field is part of another formula, whose result is this field.');
      return;
    }
    // else if (inputFields[0] === popupSelected?.id?.split('_')[1] && inputFields?.length === 1) {
    //     message.error('Circular Formula Found, Current field is part of another formula, whose result is this field.')
    //     return
    // }
    console.log(
      'hey -> ',
      inputFields.map((f) => editableObject.fieldIds.filter((e) => e.split('_')[1] === f)[0]),
      popupSelected.id
    );
    if (inputFields.length > 0)
      setEditableObject((prevObject) => {
        let newObject = { ...prevObject };
        let formulaExist = newObject.formulas?.filter((e) => e.resultField === popupSelected.id);
        if (formulaExist.length > 0) {
          if (newObject.formulas?.filter((e) => e.resultField === popupSelected.id).length > 0) {
            newObject.formulas?.forEach((f) => {
              if (f.resultField === popupSelected.id) {
                f.formula = inputVal;
                f.ids = inputFields.map((f) => editableObject.fieldIds.filter((e) => e.split('_')[1] === f)[0]);
              }
            });
          }
        } else {
          newObject.formulas?.push({
            ids: inputFields.map((f) => editableObject.fieldIds.filter((e) => e.split('_')[1] === f)[0]),
            formula: inputVal,
            resultField: popupSelected.id,
          });
        }

        return newObject;
      });

    setIsModalOpen(false);
    refreshAnswers();
  };

  const handleClearFormula = (e) => {
    console.log({ popupSelected });
    setIsModalOpen(false);
    setEditableObject((prevObject) => {
      let newObject = { ...prevObject };
      newObject.formulas = newObject.formulas?.filter((e) => e.resultField !== popupSelected.id);
      return newObject;
    });
    console.log(e);
  };

  const handleHeaderCheckbox = (e, cellIndex, rowIndex, sectionName, index) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject.sections[index][sectionName].rows[rowIndex][cellIndex].isHeader = e.target.checked;
      return newObject;
    });
  };

  const handleColspanTextarea = (e, cellIndex, rowIndex, sectionName, index) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject.sections[index][sectionName].rows[rowIndex][cellIndex].colspan = e.target.value;
      return newObject;
    });
  };

  const handleExhibitNameChange = (id, k, type) => {
    if (type === 'add') {
      setNewExhibit({ ...newExhibit, name: k.target.value });
    } else if (type === 'update') {
      let ExhibitForm = new FormData();

      ExhibitForm.append('simulation_id', editableObjectMeta?.simulation_id);
      ExhibitForm.append('exhibit_id', e);
      ExhibitForm.append('title', k.target.value);

      apiServices
        .post('assessment/update-exhibits/', ExhibitForm)
        .then((e) => console.log(e))
        .catch((e) => console.log(e));
    }
  };

  const handleDelete = (id) => {
    apiServices.delete(`assessment/delete-exhibits/${id}`).then((e) => console.log(e));
  };

  const handleImageUpload = (e, k) => {
    let ExhibitForm = new FormData();

    ExhibitForm.append('simulation_id', editableObjectMeta?.simulation_id);
    ExhibitForm.append('exhibit_id', e);
    ExhibitForm.append('exhibits_file', k.file.originFileObj);

    apiServices
      .post('assessment/update-exhibits/', ExhibitForm)
      .then((e) => console.log(e))
      .catch((e) => console.log(e));
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleUploadChange = async (k) => {
    console.log(k);
    setFileList([...k.fileList]);
    setNewExhibit({ ...newExhibit, exhibits_file: k.file.originFileObj });
    setPreviewImage(await getBase64(k.file.originFileObj));
  };

  const handleRemoveImage = (e) => {
    setNewExhibit({ ...newExhibit, exhibits_file: '' });
    setPreviewImage('');
  };

  const handleAdd = (e) => {
    if (!newExhibit.name) {
      message.error('Proper Exhibit Name Missing.');
      return;
    }
    if (!newExhibit.exhibits_file) {
      message.error('Proper Exhibit File Missing.');
      return;
    }

    let ExhibitForm = new FormData();

    ExhibitForm.append('simulation_id', editableObjectMeta?.simulation_id);
    ExhibitForm.append('title', newExhibit.name);
    ExhibitForm.append('exhibits_file', newExhibit.exhibits_file);

    apiServices
      .post('assessment/update-exhibits/', ExhibitForm)
      .then((e) => console.log(e))
      .catch((e) => console.log(e));
    apiServices.get(`assessment/get-simulation-detail/${editableObjectMeta.simulation_id}`).then((e) => {
      console.log(e);
      setEditableObject({ ...e[0].question_json });
      setEditableObjectMeta({
        course_id: e[0]?.course?.id,
        solution_description: e[0]?.solution_description,
        chapter_id: e[0]?.chapter?.id,
        course_id: e[0]?.course?.id,
        level: e[0]?.level,
        pass_percentage: e[0]?.pass_percentage,
        question_number: e[0]?.idnumber,
        simulation_category: e[0]?.sim_type?.id,
        simulation_id: e[0]?.id,
        subject_id: e[0]?.subject?.id,
        exhibits_file: e[0]?.exhibits,
      });
      setOriginalHash(
        hash({
          ...e[0]?.question_json,
          ...{
            course_id: e[0]?.course?.id,
            solution_description: e[0]?.solution_description,
            chapter_id: e[0]?.chapter?.id,
            course_id: e[0]?.course?.id,
            level: e[0]?.level,
            pass_percentage: e[0]?.pass_percentage,
            question_number: e[0]?.idnumber,
            simulation_category: e[0]?.sim_type?.id,
            simulation_id: e[0]?.id,
            subject_id: e[0]?.subject?.id,
          },
        })
      );
    });

    setNewExhibit({
      name: '',
      exhibits_file: '',
    });
    setPreviewImage('');
    setFileList([]);
    // message.info('New Exhibit Added')
  };

  const handleAddCell = () => {
    console.log({ newCell });
    // console.log(editableObject, editableObjectMeta)
    const { cellIndex, rowIndex, sectionName, index, place, type } = newCell;
    let obj = {};
    if (type === 'dyninputfield_') {
      obj = {
        colspan: 1,
        prefix: '',
        type: 'input',
        value: '',
      };

      obj.id = `dyninputfield_DIF${editableObject.fieldIds.length + 1}`;
    } else if (type === 'dynselopt_') {
      obj = {
        type: 'select',
        value: '2',
        colspan: 1,
        options: ['1', '2', '3'],
      };
      obj.id = `dynselopt_DIF${editableObject.fieldIds.length + 1}`;
    } else if (type === 'text') {
      console.log({ type });
      obj = {
        type: 'text',
        value: '252 Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
        colspan: 1,
      };
    }

    setEditableObject((prevObj) => {
      let newObj = { ...prevObj };
      console.log({ prevObj, type });
      if (type === 'dynselopt_' || type === 'dyninputfield_') {
        newObj.fieldIds.push(obj.id);
      }

      if (place < 0) {
        // newObj.sections[index][sectionName].rows[rowIndex].slice(0, cellIndex).map(e => console.log(e))
        // console.log(obj)
        // newObj.sections[index][sectionName].rows[rowIndex].slice(cellIndex, newObj.sections[index][sectionName].rows[rowIndex].length).map(e => console.log(e))

        let rowArray = [];
        newObj.sections[index][sectionName].rows[rowIndex].slice(0, cellIndex).map((e) => rowArray.push(e));
        rowArray.push(obj);
        newObj.sections[index][sectionName].rows[rowIndex]
          .slice(cellIndex, newObj.sections[index][sectionName].rows[rowIndex].length)
          .map((e) => rowArray.push(e));
        console.log({ rowArray });
        newObj.sections[index][sectionName].rows[rowIndex] = [...rowArray];
      } else {
        // newObj.sections[index][sectionName].rows[rowIndex].slice(0, cellIndex + 1).map(e => console.log(e))
        // console.log(obj)
        // newObj.sections[index][sectionName].rows[rowIndex].slice(cellIndex + 1, newObj.sections[index][sectionName].rows[rowIndex].length).map(e => console.log(e))

        let rowArray = [];
        newObj.sections[index][sectionName].rows[rowIndex].slice(0, cellIndex + 1).map((e) => rowArray.push(e));
        rowArray.push(obj);
        newObj.sections[index][sectionName].rows[rowIndex]
          .slice(cellIndex + 1, newObj.sections[index][sectionName].rows[rowIndex].length)
          .map((e) => rowArray.push(e));
        console.log({ rowArray });
        newObj.sections[index][sectionName].rows[rowIndex] = [...rowArray];
      }

      return newObj;
    });
    setIsCellModalOpen(false);
  };

  const handleDeleteCell = (e, cellIndex, rowIndex, sectionName, index) => {
    console.log({ e, cellIndex, rowIndex, sectionName, index });
    setEditableObject((prevObj) => {
      let newObj = { ...prevObj };
      newObj.fieldIds = newObj.fieldIds.filter(
        (e) => e !== newObj.sections[index][sectionName].rows[rowIndex][cellIndex].type
      );
      newObj.sections[index][sectionName].rows[rowIndex] = [
        ...newObj.sections[index][sectionName].rows[rowIndex].filter((e, i) => i !== cellIndex),
      ];
      return newObj;
    });
  };

  const handlNewCellModalChange = (e) => {
    console.log('Change in Modal Select : ', { e });
    setNewCell({ ...newCell, type: e });
  };

  const handleIDChange = (e, cellIndex, rowIndex, sectionName, index) => {
    setEditableObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject.fieldIds = newObject.fieldIds.filter(
        (e) => e !== newObject['sections'][index][sectionName].rows[rowIndex][cellIndex].id
      );
      let val = '';
      if (newObject['sections'][index][sectionName].rows[rowIndex][cellIndex].type === 'select') {
        val = `dynselopt_${e.target.value}`;
      } else if (newObject['sections'][index][sectionName].rows[rowIndex][cellIndex].type === 'input') {
        val = `dyninputfield_${e.target.value}`;
      }
      newObject['sections'][index][sectionName].rows[rowIndex][cellIndex].id = val;
      newObject.fieldIds.includes(val) ? '' : newObject.fieldIds.push(val);
      return newObject;
    });
  };

  const [fileList, setFileList] = useState([]);

  const refreshAnswers = () => {
    let answers = [];
    for (const section of editableObject.sections) {
      if (Object.values(section)[0].type === 'table') {
        for (const row of Object.values(section)[0].rows) {
          for (const cell of row) {
            if (cell.type === 'select' || cell.type === 'input') {
              answers.push(cell.value);
            }
          }
        }
      }
    }
    setEditableObjectMeta({
      ...editableObjectMeta,
      answer: answers.map((e) => `${e}`),
    });
    console.log({ answers: answers });
  };

  const refreshFieldValues = () => {
    let fieldValues = [];
    let selectFields = 0;
    for (const section of editableObject.sections) {
      if (Object.values(section)[0].type === 'table') {
        for (const row of Object.values(section)[0].rows) {
          for (const cell of row) {
            if (cell.type === 'select' || cell.type === 'input') {
              fieldValues.push(cell.id);
              // if (cell.type === 'input') {
              //     cell.value
              // }
              if (cell.type === 'select') {
                selectFields += 1;
              }
            }
          }
        }
      }
    }

    console.log({ fieldValues });

    let couldBeFieldValues = {},
      i = 0;

    for (const val of fieldValues) {
      couldBeFieldValues[val] = `${val.split('_')[0]}_DIF${i + 1}`;
      i += 1;
    }

    console.log({ editableObject, couldBeFieldValues });

    setEditableObject((prevEditableObject) => {
      let newObj = { ...prevEditableObject };
      // Changes in fieldIds
      newObj.fieldIds = [...Object.values(couldBeFieldValues)];
      newObj.totalSelectFields = new Array(selectFields).fill('');
      console.log(Object.keys(newObj));
      if (Object.keys(newObj).includes('formulas')) {
        // Changes in Formulas
        let newFormulas = [];
        if (newObj.formulas) {
          for (const formula of newObj.formulas) {
            if (couldBeFieldValues[formula.resultField]) {
              let newFormula = { ...formula };
              for (const DIF of newFormula.formula.match(/\bDIF\d+\b/g)) {
                newFormula.formula.replace(DIF, couldBeFieldValues[DIF]);
              }
              newFormula.ids = [...newFormula.ids.map((e) => couldBeFieldValues[e])];
              newFormula.resultField = couldBeFieldValues[formula.resultField];
              newFormulas.push(newFormula);
            }
          }
        }
        newObj.formulas = newFormulas;
        console.log({ newFormulas });
      }

      // Changes in sections
      for (const section of newObj.sections) {
        if (Object.values(section)[0].type === 'table') {
          for (const row of Object.values(section)[0].rows) {
            for (const cell of row) {
              if (cell.type === 'select' || cell.type === 'input') {
                cell.id = couldBeFieldValues[cell.id];
                console.log({ cell });
                if (cell.prefix) {
                  cell.value = parseCurrencyString(cell.value);
                }
              }
            }
          }
        }
      }

      // console.log({ newObj })
      return newObj;
    });
  };

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    style: 'decimal',
  });

  const parseCurrencyString = (val) => {
    if (typeof val !== 'string') return val;
    let stack = [];
    for (let i = 0; i < val.length; i++) {
      if (val[i] === '.') {
        stack.push('.');
      } else if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(val[i])) {
        stack.push(val[i]);
      } else if (val[i] === '-') {
        stack.push(val[i]);
      }
    }

    console.log(stack);
    return Number(stack.join(''));
  };

  const items = [
    {
      key: '1',
      label: 'Meta',
      children: (
        <div>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td>Course Id</td>
                <td>
                  <Input value={editableObjectMeta?.course_id} onChange={(e) => UpdateMeta(e, 'course_id')} />
                </td>
              </tr>
              <tr>
                <td>Subject Id</td>
                <td>
                  <Input value={editableObjectMeta?.subject_id} onChange={(e) => UpdateMeta(e, 'subject_id')} />
                </td>
              </tr>
              <tr>
                <td>Chapter Id</td>
                <td>
                  <Input value={editableObjectMeta?.chapter_id} onChange={(e) => UpdateMeta(e, 'chapter_id')} />
                </td>
              </tr>
              <tr>
                <td>Question No.</td>
                <td>
                  <Input
                    value={editableObjectMeta?.question_number}
                    onChange={(e) => UpdateMeta(e, 'question_number')}
                  />
                </td>
              </tr>
              <tr>
                <td>Simulation Category</td>
                <td>
                  <Input
                    value={editableObjectMeta?.simulation_category}
                    onChange={(e) => UpdateMeta(e, 'simulation_category')}
                  />
                </td>
              </tr>
              <tr>
                <td>Simulation Id</td>
                <td>
                  <Input value={editableObjectMeta?.simulation_id} onChange={(e) => UpdateMeta(e, 'simulation_id')} />
                </td>
              </tr>
              <tr>
                <td>Solution Description</td>
                <td>
                  <JoditEditor
                    value={editableObjectMeta?.solution_description}
                    onChange={(e) => UpdateMeta(e, 'solution_description', true)}
                  />
                </td>
              </tr>
              <tr>
                <td>Level</td>
                <td>
                  <Input value={editableObjectMeta?.level} onChange={(e) => UpdateMeta(e, 'level')} />
                </td>
              </tr>
              <tr>
                <td>Pass Percentage</td>
                <td>
                  <Input
                    addonAfter={<>%</>}
                    value={editableObjectMeta?.pass_percentage}
                    onChange={(e) => UpdateMeta(e, 'pass_percentage')}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Question',
      children: (
        <>
          <div>
            <Flex wrap gap={'small'} style={{ width: '100%', marginBottom: '1rem' }} align="center">
              <Title level={2} style={{ margin: 0 }}>
                Sim Answers
              </Title>
              <Button onClick={() => refreshAnswers()}>Refresh</Button>
            </Flex>
            <Flex wrap gap={'small'} style={{ width: '100%', marginBottom: '1rem' }} align="stretch">
              {editableObjectMeta?.answer?.map((e) => (
                <Tag>{e}</Tag>
              ))}
            </Flex>
          </div>
          <div>
            <Flex wrap gap={'small'} style={{ width: '100%', marginBottom: '1rem' }} align="center">
              <Title level={2} style={{ margin: 0 }}>
                Field Ids
              </Title>
              <Button onClick={() => refreshFieldValues()}>Refresh</Button>
            </Flex>
            <Flex wrap gap={'small'} style={{ width: '100%', marginBottom: '1rem' }} align="stretch">
              {editableObject?.fieldIds?.map((e) => (
                <Tag>{e}</Tag>
              ))}
            </Flex>
          </div>
          <div>
            {editableObject?.sections?.map((section, index) => {
              const sectionName = Object.keys(section)[0];
              const sectionContent = section[sectionName];

              if (sectionContent.type === 'paragraph') {
                return (
                  <div key={index}>
                    <Title level={4}>{`${sectionName.split('_')[0]}: ${sectionName.split('_')[1]}`}</Title>
                    <JoditEditor value={sectionContent.value} onChange={(e) => handleParagraphChange(e, sectionName)} />
                  </div>
                );
              }

              if (sectionContent.type === 'table') {
                return (
                  <div key={index} style={{ zIndex: '-999' }}>
                    <Title level={4}>{`${sectionName.split('_')[0]}: ${sectionName.split('_')[1]}`}</Title>
                    <table style={{ width: '100%', padding: '0 0 1rem 0', background: '#ececec' }}>
                      <tbody
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'stretch',
                        }}
                      >
                        {sectionContent.rows.map((row, rowIndex) => (
                          <Fragment>
                            {rowIndex === 0 ? (
                              <Flex wrap="true" vertical={false} align="center" justify="space-evenly">
                                <Button
                                  type="default"
                                  onClick={(e) => {
                                    console.log();
                                    setEditableObject((prevObj) => {
                                      let cell = {
                                        type: 'text',
                                        value: '',
                                        colspan: 1,
                                      };

                                      let newObj = { ...prevObj };
                                      newObj.sections.forEach((e) => {
                                        if (Object.values(e)[0].type === 'table' && Object.keys(e)[0] === sectionName) {
                                          let slicedRows = e[Object.keys(e)[0]].rows.slice(0, rowIndex); // slice the array from index 1 to 2
                                          let remainingRows = e[Object.keys(e)[0]].rows.slice(rowIndex); // concatenate the remaining parts with the new array
                                          e[Object.keys(e)[0]].rows = [...slicedRows, [cell], ...remainingRows];
                                        }
                                      });
                                      return newObj;
                                    });
                                  }}
                                >
                                  Add Row
                                </Button>
                                <Button
                                  type="default"
                                  onClick={() => {
                                    setEditableObject((prevObj) => {
                                      let cell = {
                                        type: 'text',
                                        value: '',
                                        colspan: 1,
                                      };

                                      let newObj = { ...prevObj };
                                      newObj.sections.forEach((e) => {
                                        if (Object.values(e)[0].type === 'table' && Object.keys(e)[0] === sectionName) {
                                          let slicedRows = e[Object.keys(e)[0]].rowExplanations.slice(0, rowIndex); // slice the array from index 1 to 2
                                          let remainingRows = e[Object.keys(e)[0]].rowExplanations.slice(rowIndex); // concatenate the remaining parts with the new array
                                          e[Object.keys(e)[0]].rowExplanations = [
                                            ...slicedRows,
                                            'Type Here',
                                            ...remainingRows,
                                          ];

                                          slicedRows = e[Object.keys(e)[0]].rows.slice(0, rowIndex); // slice the array from index 1 to 2
                                          remainingRows = e[Object.keys(e)[0]].rows.slice(rowIndex); // concatenate the remaining parts with the new array
                                          e[Object.keys(e)[0]].rows = [...slicedRows, [cell], ...remainingRows];
                                        }
                                      });
                                      return newObj;
                                    });
                                  }}
                                >
                                  Add Row with Explanation
                                </Button>
                              </Flex>
                            ) : (
                              ''
                            )}
                            <tr
                              key={rowIndex}
                              onClick={(e) => console.log('onFocus', e)}
                              onBlur={(e) => console.log('onBlur', e)}
                              onFocusCapture={(e) => console.log('onFocusCapture', e)}
                              onBlurCapture={(e) => console.log('onBlurCapture', e)}
                              style={{
                                margin: '0.5rem 0.5rem',
                                padding: '0.5rem',
                                border: '1px solid #efefef',
                                background: '#fafafa',
                                overflowX: 'auto',
                              }}
                            >
                              <Flex wrap gap={'small'} style={{ width: '100%', marginBottom: '1rem' }} align="stretch">
                                {row.map((cell, cellIndex) => (
                                  <td
                                    style={{
                                      width: '100%',
                                      height: 'fit-content',
                                      padding: '5px',
                                      border: '1px solid #333',
                                    }}
                                    key={cellIndex}
                                  >
                                    {cell.type === 'text' ? (
                                      <Fragment>
                                        <TextArea
                                          id={cell.id}
                                          key={cellIndex}
                                          style={{ width: '100%' }}
                                          autoSize
                                          value={cell.value}
                                          onChange={(e) =>
                                            handleTextAreaChange(e, cellIndex, rowIndex, sectionName, index)
                                          }
                                        />
                                        <Flex
                                          vertical={false}
                                          style={{ marginTop: '1rem' }}
                                          align="center"
                                          gap={'1rem'}
                                        >
                                          <Button
                                            title="Add Cell Before"
                                            onClick={() => {
                                              setIsCellModalOpen(true);
                                              setNewCell({
                                                ...newCell,
                                                cellIndex,
                                                rowIndex,
                                                sectionName,
                                                index,
                                                place: -1,
                                              });
                                            }}
                                            type="default"
                                          >
                                            <AppstoreAddOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                          <Checkbox
                                            checked={cell.isHeader}
                                            onChange={(e) =>
                                              handleHeaderCheckbox(e, cellIndex, rowIndex, sectionName, index)
                                            }
                                          >
                                            isHeader
                                          </Checkbox>
                                          <Input
                                            addonBefore={<>colspan</>}
                                            id={`${cell.id}_colspan`}
                                            key={`${cellIndex}_colspan`}
                                            style={{ minWidth: 120 }}
                                            autoSize
                                            value={cell?.colspan}
                                            onChange={(e) =>
                                              handleColspanTextarea(e, cellIndex, rowIndex, sectionName, index)
                                            }
                                          />
                                          <Button
                                            title="Delete Cell"
                                            onClick={(e) =>
                                              handleDeleteCell(e, cellIndex, rowIndex, sectionName, index)
                                            }
                                            type="default"
                                            danger
                                          >
                                            <DeleteOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                          <Button
                                            title="Add Cell After"
                                            onClick={() => {
                                              setIsCellModalOpen(true);
                                              setNewCell({
                                                ...newCell,
                                                cellIndex,
                                                rowIndex,
                                                sectionName,
                                                index,
                                                place: 1,
                                              });
                                            }}
                                            type="default"
                                          >
                                            <AppstoreAddOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                        </Flex>
                                      </Fragment>
                                    ) : cell.type === 'select' ? (
                                      <Flex vertical gap="small">
                                        <Input
                                          addonBefore={<b>id</b>}
                                          value={cell.id.split('dynselopt_')[1]}
                                          onChange={(e) => handleIDChange(e, cellIndex, rowIndex, sectionName, index)}
                                        />
                                        <span style={{ fontWeight: 'bold' }}>Options</span>
                                        {cell.options.map((option, optionIndex) => (
                                          <Input
                                            value={option}
                                            key={optionIndex}
                                            onChange={(e) => {
                                              handleOptionChange(
                                                e,
                                                optionIndex,
                                                cellIndex,
                                                rowIndex,
                                                sectionName,
                                                index
                                              );
                                            }}
                                            addonAfter={
                                              <CloseCircleOutlined
                                                onClick={(e) => {
                                                  removeOption(e, option, cellIndex, rowIndex, sectionName, index);
                                                }}
                                              />
                                            }
                                          />
                                        ))}
                                        <Button
                                          size="middle"
                                          style={{ width: 'fit-content' }}
                                          onClick={(e) => addOption(e, cellIndex, rowIndex, sectionName, index)}
                                        >
                                          add option <AppstoreAddOutlined style={{ cursor: 'pointer' }} />
                                        </Button>
                                        <span style={{ fontWeight: 'bold' }}>Right Answer</span>
                                        <Select
                                          value={cell.value}
                                          style={{ width: '100%' }}
                                          onChange={(e) =>
                                            handleChangeAnswer(e, cellIndex, rowIndex, sectionName, index)
                                          }
                                          options={cell.options.map((e) => ({ label: e, value: e }))}
                                        />
                                        <Flex
                                          vertical={false}
                                          style={{ marginTop: '1rem' }}
                                          align="center"
                                          justify="space-between"
                                          gap={'1rem'}
                                        >
                                          <Button
                                            title="Add Cell Before"
                                            onClick={() => {
                                              setIsCellModalOpen(true);
                                              setNewCell({
                                                ...newCell,
                                                cellIndex,
                                                rowIndex,
                                                sectionName,
                                                index,
                                                place: -1,
                                              });
                                            }}
                                            type="default"
                                          >
                                            <AppstoreAddOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                          <Button
                                            title="Delete Cell"
                                            onClick={(e) =>
                                              handleDeleteCell(e, cellIndex, rowIndex, sectionName, index)
                                            }
                                            type="default"
                                            danger
                                          >
                                            <DeleteOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                          <Button
                                            title="Add Cell After"
                                            onClick={() => {
                                              setIsCellModalOpen(true);
                                              setNewCell({
                                                ...newCell,
                                                cellIndex,
                                                rowIndex,
                                                sectionName,
                                                index,
                                                place: 1,
                                              });
                                            }}
                                            type="default"
                                          >
                                            <AppstoreAddOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                        </Flex>
                                      </Flex>
                                    ) : (
                                      <Flex vertical gap="small">
                                        <TextArea
                                          id="cell-textarea"
                                          key={cellIndex}
                                          style={{ width: '100%' }}
                                          autoSize
                                          value={formatter.format(cell?.value)}
                                          onChange={(e) =>
                                            handleInputChange(e, cellIndex, rowIndex, sectionName, index)
                                          }
                                        />
                                        <Flex
                                          vertical={false}
                                          style={{ marginTop: '1rem' }}
                                          align="center"
                                          gap={'1rem'}
                                        >
                                          <Button
                                            title="Add Cell Before"
                                            onClick={() => {
                                              setIsCellModalOpen(true);
                                              setNewCell({
                                                ...newCell,
                                                cellIndex,
                                                rowIndex,
                                                sectionName,
                                                index,
                                                place: -1,
                                              });
                                            }}
                                            type="default"
                                          >
                                            <AppstoreAddOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                          <Input
                                            addonBefore={<>Prefix</>}
                                            placeholder="$"
                                            onChange={(e) =>
                                              handlePrefixChange(e, cellIndex, rowIndex, sectionName, index)
                                            }
                                            value={cell.prefix}
                                            style={{ minWidth: 120 }}
                                          />
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              showModal();
                                              setPopupSelected({
                                                ...cell,
                                                ...newCell,
                                                inputVal:
                                                  editableObject.formulas?.filter((e) => e.resultField === cell.id)[0]
                                                    ?.formula || '',
                                              });
                                              console.log({ cell, newCell });
                                            }}
                                          >
                                            {cell?.id?.split('dyninputfield_')[1]}{' '}
                                            {editableObject.formulas?.filter((e) => e.resultField === cell.id).length >
                                            0
                                              ? ` = ${
                                                  editableObject.formulas?.filter((e) => e.resultField === cell.id)[0]
                                                    .formula
                                                }`
                                              : ''}
                                          </Button>
                                          <Button
                                            title="Delete Cell"
                                            onClick={(e) =>
                                              handleDeleteCell(e, cellIndex, rowIndex, sectionName, index)
                                            }
                                            type="default"
                                            danger
                                          >
                                            <DeleteOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                          <Button
                                            title="Add Cell After"
                                            onClick={() => {
                                              setIsCellModalOpen(true);
                                              setNewCell({
                                                ...newCell,
                                                cellIndex,
                                                rowIndex,
                                                sectionName,
                                                index,
                                                place: 1,
                                              });
                                            }}
                                            type="default"
                                          >
                                            <AppstoreAddOutlined style={{ cursor: 'pointer' }} />
                                          </Button>
                                        </Flex>
                                      </Flex>
                                    )}
                                  </td>
                                ))}
                              </Flex>
                              {row.length === 0 && removeRow(rowIndex, sectionName, index)}
                              {sectionContent?.rowExplanations[rowIndex] && (
                                <td key={`rowExplanation-${rowIndex}`}>
                                  <h4 style={{ fontWeight: 'bold', margin: '0 0 6px 4px' }}>Row Explanation</h4>
                                  <JoditEditor
                                    value={sectionContent.rowExplanations[rowIndex]}
                                    onChange={(e) => changeRowExplanation(e, rowIndex, sectionName, index)}
                                  />
                                </td>
                              )}
                            </tr>
                            <Flex wrap="true" vertical={false} align="center" justify="space-evenly">
                              <Button
                                type="default"
                                onClick={(e) => {
                                  console.log();
                                  setEditableObject((prevObj) => {
                                    let cell = {
                                      type: 'text',
                                      value: '',
                                      colspan: 1,
                                    };

                                    let newObj = { ...prevObj };
                                    newObj.sections.forEach((e) => {
                                      if (Object.values(e)[0].type === 'table' && Object.keys(e)[0] === sectionName) {
                                        let slicedRows = e[Object.keys(e)[0]].rows.slice(0, rowIndex + 1); // slice the array from index 1 to 2
                                        let remainingRows = e[Object.keys(e)[0]].rows.slice(rowIndex + 1); // concatenate the remaining parts with the new array
                                        e[Object.keys(e)[0]].rows = [...slicedRows, [cell], ...remainingRows];
                                      }
                                    });
                                    return newObj;
                                  });
                                }}
                              >
                                Add Row
                              </Button>
                              <Button
                                type="default"
                                onClick={() => {
                                  setEditableObject((prevObj) => {
                                    let cell = {
                                      type: 'text',
                                      value: '',
                                      colspan: 1,
                                    };

                                    let newObj = { ...prevObj };
                                    newObj.sections.forEach((e) => {
                                      if (Object.values(e)[0].type === 'table' && Object.keys(e)[0] === sectionName) {
                                        let slicedRows = e[Object.keys(e)[0]].rowExplanations.slice(0, rowIndex + 1); // slice the array from index 1 to 2
                                        let remainingRows = e[Object.keys(e)[0]].rowExplanations.slice(rowIndex + 1); // concatenate the remaining parts with the new array
                                        e[Object.keys(e)[0]].rowExplanations = [
                                          ...slicedRows,
                                          'Type Here',
                                          ...remainingRows,
                                        ];

                                        slicedRows = e[Object.keys(e)[0]].rows.slice(0, rowIndex + 1); // slice the array from index 1 to 2
                                        remainingRows = e[Object.keys(e)[0]].rows.slice(rowIndex + 1); // concatenate the remaining parts with the new array
                                        e[Object.keys(e)[0]].rows = [...slicedRows, [cell], ...remainingRows];
                                      }
                                    });
                                    return newObj;
                                  });
                                }}
                              >
                                Add Row with Explanation
                              </Button>
                            </Flex>
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                );
              }
            })}
          </div>
        </>
      ),
    },
    {
      key: '3',
      label: 'Exhibit',
      children: (
        <>
          <Flex gap={'middle'} wrap="wrap">
            {editableObjectMeta?.exhibits_file?.map((e, i) => (
              <Flex
                vertical={true}
                gap={'small'}
                align="flex-start"
                style={{ background: '#EaEaEa', padding: '0.5rem' }}
              >
                <Input
                  placeholder="filename"
                  size="middle"
                  value={e.name}
                  onChange={(k) => handleExhibitNameChange(e.id, k, 'update')}
                />
                <Flex vertical={false} gap={'small'} align="flex-start">
                  <Upload action="#" onChange={(k) => handleImageUpload(e.id, k)}>
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  </Upload>
                  {typeof e.exhibits_file === 'string' ? (
                    <Image width={200} src={e.exhibits_file} />
                  ) : (
                    <Image width={200} src={URL.createObjectURL(e.exhibits_file.originFileObj) || ''} />
                  )}
                </Flex>
                <Button
                  style={{ justifySelf: 'flex-end' }}
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(e.id)}
                >
                  Delete Exhibit
                </Button>
              </Flex>
            ))}
            <Flex vertical={true} gap={'small'} align="flex-start" style={{ background: '#eaeaea', padding: '0.5rem' }}>
              <Input
                placeholder="filename"
                size="middle"
                value={newExhibit.name}
                onChange={(k) => handleExhibitNameChange(null, k, 'add')}
              />
              <Flex vertical={false} gap={'small'} align="flex-start">
                <Upload
                  action="#"
                  fileList={fileList}
                  multiple={false}
                  onChange={handleUploadChange}
                  onRemove={handleRemoveImage}
                >
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
                <Button icon={<FileImageOutlined />} onClick={handleAdd}>
                  Add Exhibit
                </Button>
                <Image width={200} src={previewImage} />
              </Flex>
            </Flex>
          </Flex>
        </>
      ),
    },
    {
      key: '4',
      label: 'Preview',
      children: (
        <div>
          <SimBody
            simData={editableObject}
            handleSetFormula={() =>
              message.info('To change or edit Simulation, Goto Edit Part, by clicking Hide Preview.')
            }
          />
        </div>
      ),
    },
    {
      key: '5',
      label: 'JSON Editor (DO NOT ENTER)',
      children: (
        <>
          {Object.entries(editableObject).map(
            (e) =>
              e[0] !== 'simType' && (
                <>
                  <Typography.Text strong>{e[0]}</Typography.Text>
                  <TextArea
                    style={{ height: 300 }}
                    onChange={(k) =>
                      setEditableObject({
                        ...editableObject,
                        [e[0]]: typeof k.value === 'object' ? JSON.parse(k?.value) : k.value,
                      })
                    }
                    value={JSON.stringify(e[1], null, 2)}
                  />
                </>
              )
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Title level={1}>Edit Simulation</Title>
      <Tabs defaultActiveKey="1" items={items} onChange={(e) => console.log(e)} />
      <Modal
        title="Formula Editor"
        open={isModalOpen}
        onOk={handleFormulaUpdate}
        onCancel={handleCancel}
        footer={[
          <Button key="clear" type="dashed" danger onClick={handleClearFormula}>
            Remove Formula
          </Button>,
          <Button key="back" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleFormulaUpdate}>
            Ok
          </Button>,
        ]}
      >
        <Input
          addonBefore={<>Formula = </>}
          value={popupSelected.inputVal}
          onChange={(e) => setPopupSelected({ ...popupSelected, inputVal: e.target.value })}
        />
      </Modal>
      <Modal
        open={isCellModelOpen}
        onOk={handleAddCell}
        onCancel={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Flex vertical={false} align="center" gap={'1rem'}>
          <Typography.Text strong>Type</Typography.Text>
          <Select
            defaultValue="dynselopt_"
            onChange={handlNewCellModalChange}
            style={{ width: 160 }}
            options={[
              { value: 'dynselopt_', label: 'Select Option' },
              { value: 'text', label: 'Text Area' },
              { value: 'dyninputfield_', label: 'Dynamic Input' },
            ]}
          />
        </Flex>
      </Modal>
      {change && (
        <Button
          type="default"
          shape="default"
          icon={<DownloadOutlined />}
          size={'large'}
          style={{ position: 'fixed', bottom: '20px', right: '20px' }}
          onClick={handleSave}
        >
          Save
        </Button>
      )}
    </>
  );
}
