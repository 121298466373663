import React, { useState, useEffect } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, $createTextNode, createCommand } from 'lexical';

const HIGHLIGHT_MCQ_COMMAND = createCommand('HIGHLIGHT_MCQ');

function HighlightMCQPlugin({ onMCQHighlight }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      HIGHLIGHT_MCQ_COMMAND,
      () => {
        editor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const text = selection.getTextContent();
            const highlightedNode = $createTextNode(text);
            highlightedNode.setStyle('background-color: yellow; padding: 2px;');
            selection.insertNodes([highlightedNode]);
            onMCQHighlight(text);
          }
        });
        return true;
      },
      1
    );
  }, [editor, onMCQHighlight]);

  return null;
}

function Toolbar() {
  const [editor] = useLexicalComposerContext();

  return (
    <div className="toolbar" style={{ padding: '10px', borderBottom: '1px solid #ccc', marginBottom: '10px' }}>
      <button
        onClick={() => {
          editor.dispatchCommand(HIGHLIGHT_MCQ_COMMAND, undefined);
        }}
      >
        Highlight as MCQ
      </button>
    </div>
  );
}

export default function DRSQuestionEditor() {
  const [mcqState, setMcqState] = useState({
    questions: [],
    selectedQuestion: null,
    optionInput: '',
  });

  const handleMCQHighlight = (text) => {
    setMcqState((prev) => ({
      ...prev,
      questions: [...prev.questions, { question: text, options: [] }],
      selectedQuestion: text,
    }));
  };

  const handleAddOption = () => {
    setMcqState((prev) => ({
      ...prev,
      questions: prev.questions.map((mcq) =>
        mcq.question === prev.selectedQuestion ? { ...mcq, options: [...mcq.options, prev.optionInput] } : mcq
      ),
      optionInput: '',
    }));
  };

  const handleDeleteMCQ = (question) => {
    setMcqState((prev) => ({
      ...prev,
      questions: prev.questions.filter((mcq) => mcq.question !== question),
      selectedQuestion: prev.selectedQuestion === question ? null : prev.selectedQuestion,
    }));
  };

  const initialConfig = {
    namespace: 'DRSEditor',
    theme: {},
    onError: (error) => console.error(error),
  };

  return (
    <div>
      {/* Lexical Editor */}
      <LexicalComposer initialConfig={initialConfig}>
        <Toolbar />
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor" />}
          placeholder={<div className="placeholder">Start typing...</div>}
        />
        <HistoryPlugin />
        <OnChangePlugin onChange={() => {}} />
        <HighlightMCQPlugin onMCQHighlight={handleMCQHighlight} />
      </LexicalComposer>

      {/* MCQ Section */}
      <div>
        <h3>MCQ Questions</h3>
        <div style={{ padding: '20px' }}>
          {mcqState.questions.map((mcq, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'start',
                flexDirection: 'column',
                gap: '6px',
              }}
            >
              <div>
                <span>{mcq.question}</span>
                <button onClick={() => handleDeleteMCQ(mcq.question)}>Delete</button>
              </div>
              <select style={{ width: '200px', padding: '4px' }}>
                {mcq.options.map((opt, i) => (
                  <option key={i} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>
        {mcqState.selectedQuestion && (
          <div style={{ paddingInlineStart: '20px' }}>
            <h4>Add Options for: {mcqState.selectedQuestion}</h4>
            <input
              type="text"
              placeholder="Enter option"
              value={mcqState.optionInput}
              onChange={(e) => setMcqState((prev) => ({ ...prev, optionInput: e.target.value }))}
            />
            <button onClick={handleAddOption}>Add Option</button>
          </div>
        )}
      </div>
    </div>
  );
}

// unanswered state, answered state,
